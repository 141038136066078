// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --main-color: #2977b6;\n  --main-dark-color: #123958;\n}\n\n._35fj1uBzaIJumGz_MImxNr {\n  flex-basis: 350px;\n  background-color: #2977b6;\n  display: flex;\n  flex-direction: column;\n}\n\n._3oDcbElo0sms23UvTadrc1 {\n  padding: 8px 24px;\n  width: 100%;\n}\n\n._2l3G2rQXg20di0Iyl_PYRn {\n  padding: 8px 24px 24px 24px;\n}\n\n._26WrFpGTnXZf1c663_Gmi6 {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.A3kN-JTfvj3RW3D0i22Im {\n  padding-bottom: 24px;\n}\n\n._1QeydpcMEZ_0IA5hr5hpKQ {\n  padding-left: 14px;\n  padding-right: 4px;\n}\n\n.Tj3uLAECvHRGUCMsSLOht {\n  width: 100%;\n}\n\n._3qi2LVrTtLpTHexix0nx6E {\n  padding-top: 16px;\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/style.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,0BAAA;AACF;;AAEA;EAEE,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,WAAA;AAAF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[":root {\n  --main-color: #2977b6;\n  --main-dark-color: #123958;\n}\n\n.tabContainer {\n  //   width: 256px;\n  flex-basis: 350px;\n  background-color: #2977b6;\n  display: flex;\n  flex-direction: column;\n}\n\n.buttonContainer {\n  padding: 8px 24px;\n  width: 100%;\n}\n\n.titleContainer {\n  padding: 8px 24px 24px 24px;\n}\n\n.checkboxContainer {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.checkboxPadBottom {\n  padding-bottom: 24px;\n}\n\n.inputContainer {\n  padding-left: 14px;\n  padding-right: 4px;\n}\n\n.button {\n  width: 100%;\n}\n\n.propertyContainer {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "_35fj1uBzaIJumGz_MImxNr",
	"buttonContainer": "_3oDcbElo0sms23UvTadrc1",
	"titleContainer": "_2l3G2rQXg20di0Iyl_PYRn",
	"checkboxContainer": "_26WrFpGTnXZf1c663_Gmi6",
	"checkboxPadBottom": "A3kN-JTfvj3RW3D0i22Im",
	"inputContainer": "_1QeydpcMEZ_0IA5hr5hpKQ",
	"button": "Tj3uLAECvHRGUCMsSLOht",
	"propertyContainer": "_3qi2LVrTtLpTHexix0nx6E"
};
export default ___CSS_LOADER_EXPORT___;
