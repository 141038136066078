// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ic3ZlfTMJvlrUtYdliSiN {\n  color: #f54343;\n  font-size: 14px;\n  font-weight: bold;\n}\n\n._1CZGcxfuObOWkv9rSAGSPa {\n  font-size: 14px;\n}\n\n._2jJO1DF2pYlnA0IqzrFnRd {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n._37Cq76gYQL4UFyddHoWW2f {\n  align-items: center;\n}\n\n._7Vmo6HpX-BSGBPiUY3mVt {\n  padding: 24px;\n}\n\n._1y-DuqI_wIRf4z-l4wdffd {\n  display: flex;\n  gap: 8px;\n  background-color: #f7f7f7;\n  padding: 24px;\n}\n\n._3haPjt8oor-DeJhP3vUGJM {\n  justify-content: space-between;\n}\n\n._3DbTbImgmksSiFnoaGUeCH {\n  background-color: #e7292b;\n}\n\n.ZnWMqxXkSmeQz23QCYIRr {\n  color: #919191;\n  background-color: #eff0f1;\n}\n\n.ZnWMqxXkSmeQz23QCYIRr:hover {\n  background-color: #def6ff;\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/module-editor/popup-module-editor.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,aAAA;EACA,QAAA;EACA,yBAAA;EACA,aAAA;AAEF;;AACA;EACE,8BAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,cAAA;EACA,yBAAA;AAGF;;AADA;EACE,yBAAA;AAIF","sourcesContent":[".redText {\n  color: #f54343;\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.defaultText {\n  font-size: 14px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.contentCenter {\n  align-items: center;\n}\n\n.bodyPad {\n  padding: 24px;\n}\n\n.footerContainer {\n  display: flex;\n  gap: 8px;\n  background-color: #f7f7f7;\n  padding: 24px;\n}\n\n.deleteMenuFooter {\n  justify-content: space-between;\n}\n\n.deleteBtn {\n  background-color: #e7292b;\n}\n.cancelBtn {\n  color: #919191;\n  background-color: #eff0f1;\n}\n.cancelBtn:hover{\n  background-color: #def6ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redText": "ic3ZlfTMJvlrUtYdliSiN",
	"defaultText": "_1CZGcxfuObOWkv9rSAGSPa",
	"content": "_2jJO1DF2pYlnA0IqzrFnRd",
	"contentCenter": "_37Cq76gYQL4UFyddHoWW2f",
	"bodyPad": "_7Vmo6HpX-BSGBPiUY3mVt",
	"footerContainer": "_1y-DuqI_wIRf4z-l4wdffd",
	"deleteMenuFooter": "_3haPjt8oor-DeJhP3vUGJM",
	"deleteBtn": "_3DbTbImgmksSiFnoaGUeCH",
	"cancelBtn": "ZnWMqxXkSmeQz23QCYIRr"
};
export default ___CSS_LOADER_EXPORT___;
