// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1KqE2FrArhMsqKoamu69cE {\n  margin-bottom: 32px;\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".mb32 {\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mb32": "_1KqE2FrArhMsqKoamu69cE"
};
export default ___CSS_LOADER_EXPORT___;
