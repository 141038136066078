import * as React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#FFFFFF", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a)"
      >
        <path d="M5.793 13.5H3a.5.5 0 0 1-.5-.5v-2.793a.5.5 0 0 1 .146-.353l7.708-7.708a.5.5 0 0 1 .707 0l2.793 2.792a.5.5 0 0 1 0 .706l-7.708 7.71a.5.5 0 0 1-.353.146ZM8.5 4 12 7.5" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PencilSimpleIcon = forwardRef(SvgComponent);

export default PencilSimpleIcon;
