import * as React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#20ADEB", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      ref={ref}
      {...rest}
    >
      <path
        fill={color}
        d="M11.8 14.15H3.1c-.6 0-1.1-.5-1.1-1.1V4c0-.6.5-1.1 1.1-1.1h4.6c.2 0 .4.2.4.4s-.2.4-.4.4H3.1c-.2.05-.3.15-.3.3v9.05c0 .15.15.3.3.3h8.75c.15 0 .3-.15.3-.3V7.8c0-.2.2-.4.4-.4s.4.2.4.4v5.3c-.05.6-.55 1.05-1.15 1.05Z"
      />
      <path
        fill={color}
        d="m13.55 2.5-.2-.2c-.3-.3-.7-.45-1.1-.45-.4 0-.8.15-1.1.45l-.4.4h-.05L5.75 7.6c-.05.05-.1.1-.15.2l-.65 2.45c-.05.15 0 .3.1.4.1.1.2.1.3.1h.1l2.55-.6c.05 0 .1-.05.15-.1L13.1 5.1h.05l.45-.45c.55-.55.55-1.55-.05-2.15Zm-1.3.15c.2 0 .4.1.55.25l.2.15c.3.3.3.8 0 1.1l-.15.15-1.3-1.3.15-.15c.15-.15.3-.2.55-.2Zm-4.4 6.6-1.3-1.3 4.4-4.4 1.3 1.3-4.4 4.4ZM6.2 8.7l.85.85-1.2.25.35-1.1Z"
      />
    </svg>
  );
};

const EditIcon = forwardRef(SvgComponent);

export default EditIcon;
