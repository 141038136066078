// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1FAYrCRi-tA7WXJgT2wjH5 {\n  display: flex;\n  flex-direction: column;\n  padding: 24px 24px 0 24px;\n  overflow: hidden;\n}\n\n._281IE1JlNPP0aWW1X9m_lX {\n  justify-content: center;\n}\n\n._6wTFbO2AdpupmlfwGHaok {\n  display: flex;\n  align-items: center;\n  background-color: #4eb548;\n  color: white;\n  padding: 4px 12px;\n  gap: 4px;\n  border-radius: 4px;\n}\n\n._1uzDz1Qtr3-w_TwNKwbBjL {\n  width: 100%;\n  max-width: 90vw;\n}\n\n@media (min-width: 576px) {\n  ._1uzDz1Qtr3-w_TwNKwbBjL {\n    width: 50vw;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/module-editor/module-editor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,QAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,WAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 24px 24px 0 24px;\n  overflow: hidden;\n}\n\n.justifyCenter {\n  justify-content: center;\n}\n\n.renderHeaderCellBtn {\n  display: flex;\n  align-items: center;\n  background-color: #4eb548;\n  color: white;\n  padding: 4px 12px;\n  gap: 4px;\n  border-radius: 4px;\n}\n\n.searchContainer {\n  width: 100%;\n  max-width: 90vw;\n}\n\n@media (min-width: 576px) {\n  .searchContainer {\n    width: 50vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1FAYrCRi-tA7WXJgT2wjH5",
	"justifyCenter": "_281IE1JlNPP0aWW1X9m_lX",
	"renderHeaderCellBtn": "_6wTFbO2AdpupmlfwGHaok",
	"searchContainer": "_1uzDz1Qtr3-w_TwNKwbBjL"
};
export default ___CSS_LOADER_EXPORT___;
