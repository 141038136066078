import "./popup-module-editor.scss";

import classNames from 'classnames';
import React from "react";
import { useController, useForm } from "react-hook-form";

import { Form } from "../app-editor/form";
import Button from "../app-editor/components/button/button";
import DefaultTextBox from "../../../components/text-box/text-box";
import DefaultRadioGroup from "../../../components/radio-group/radio-group";
import DefaultDialog from "../../../components/custom-dialog/default-dialog";
import DefaultSelectBox from "../../../components/select-box/select-box";
import styles from './popup-module-editor.module.scss';
import DefaultCheckbox from "../../../components/check-box/check-box";

function Input({ name, label, placeholder, errorMessage, required, onAfterChanged, pattern }) {
  const { field, fieldState } = useController({ name, rules: { required, pattern, validate: (value) => !!value.trim() } });

  const errorText = () => {
    if (fieldState.error.type === 'required' || fieldState.error.type === "validate") {
      return <span className="errorText">This field is required</span>
    };
    if (fieldState.error.type === 'pattern') {
      return (
        <span className="errorText">
          The value must be at least 3 characters, starts with an alphabet and only allow alphanumeric.
        </span>
      );
    };
    return "";
  }

  const onChange = (value) => {
    field.onChange(value);
    onAfterChanged?.(value)
  }

  return (
    <DefaultTextBox
      onTbvalueChange={onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function RadioGroupInput({ label, name, required }) {
  const { field } = useController({ name, required });
  return (
    <DefaultRadioGroup
      onValueChanged={field.onChange}
      value={field.value}
      items={[
        {
          id: 2,
          label: "Master Data",
        },
        {
          id: 3,
          label: "Document",
        },
        {
          id: 1,
          label: "Blank",
        },
      ]}
      displayExpr="label"
      valueExpr="id"
      label={label}
      displayStar={required}
    />
  );
}

function CheckboxField({ name, label, disabled }) {
  const { field } = useController({ name });

  return (
    <div style={{ width: "100%" }}>
      <DefaultCheckbox
        onValueChanged={(value) => field.onChange(value)}
        value={field.value}
        name={field.name}
        label={label}
        labelWidth={100}
        flexReverse
        isResetStyle
        align="center"
        disabled={disabled}
      />
    </div>
  );
}


function SelectInput({ label, name, required }) {
  const { field } = useController({ name, required });
  return (
    <DefaultSelectBox
      onValueChanged={field.onChange}
      value={field.value}
      items={[
        {
          id: "NONE",
          label: "-",
        },
        {
          id: "AIG",
          label: "Associated Item Group",
        },
        {
          id: "ACG",
          label: "Associated Customer Group",
        },
        {
          id: "AVG",
          label: "Associated Vendor Group",
        },
      ]}
      displayExpr="label"
      valueExpr="id"
      caption={label}
      isResetStyle
      displayStar={required}
    />
  );
}

const defaultValues = {
  TableName: "",
  MenuType: 2,
  AssociateGroup: "NONE",
  Name: "",
  Caption: "",
  Active_YN: true,
}

export default function AddMenuPopup(props) {
  const { onHiding, onConfirm } = props;

  const methods = useForm({
    defaultValues,
  });
  const { reset } = methods;

  const onSubmit = async (values) => {
    try {
      onConfirm?.(values);
    } catch (error) {
      console.error(error);
    }
  };

  const _onHiding = () => {
    reset(defaultValues);
    onHiding(false);
  };


  return (
    <DefaultDialog
      title="Add Menu"
      width={400}
      height={496}
      onClose={_onHiding}
    >
      <Form style={{ height: '100%' }} methods={methods} onSubmit={onSubmit}>
        <div className={classNames(styles.content, styles.bodyPad)} style={{ position: 'relative', flex: 1 }}>
          <div style={{ position: 'absolute', inset: 0 }}>
            <div style={{ height: '100%', overflow: 'auto', position: 'relative', padding: 24, gap: 16, display: 'flex', flexDirection: 'column' }}>
              <Input
                name="TableName"
                label="Menu Code"
                required
                pattern={/^[a-z|A-Z]{1}[a-zA-Z0-9]{2,}$/}
                errorMessage="This name must be unique and not editable after saving"
              />
              <Input name="Caption" label="Menu Name" required />
              <RadioGroupInput
                label="Type"
                name="MenuType"
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ marginLeft: 6, fontSize: 14, marginBottom: 8, fontWeight: 'bold', color: '#474751' }}>Status</span>
                <CheckboxField name="Active_YN" label="Active" />
              </div>
              <SelectInput label="Associated Group" name="AssociateGroup" />
            </div>
          </div>
        </div>
        <div className={styles.footerContainer} >
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </DefaultDialog>
  );
}
